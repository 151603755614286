/**
 * @author Erdem Arslan
 */

(function() {
    'use strict';

    var app = (function() {
        var modules = [];

        $.fn.exists = function (callback) {
            var args = [].slice.call(arguments, 1);
            if (this.length) {
                callback.call(this, args);
            }
            return this;
        }

        var registerInit = function(module) {
            modules.push(module);
        };

        var init = function() {
            $('.collapsible').exists(function(){
                accordion('.collapsible');
            });
            scrollDown('#scroll-down');
        };

        var initModules = function() {
            if(modules.length > 0) {
                modules.forEach(function(module) {
                    if(typeof app[module] !== 'undefined' && !app[module].isInitialized()) {
                        app[module].init();
                    }
                });
            }
        };

        var ready = function(fn) {
            if(document.readyState !== 'loading') {
                fn();
            } else {
                document.addEventListener('DOMContentLoaded', fn);
            }
        };

        var scrollDown = function(className) {
            $(className).click(function() {
                if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
                    var target = $(this.hash);
                    target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
                    if (target.length) {
                        $('html, body').animate({
                            scrollTop: target.offset().top
                        }, 1000);
                        return false;
                    }
                }
            });
        };

        var accordion = function(className) {
            $(className).collapsible({
                closeSiblings: true
            });
        };

        return {
            init:         init,
            registerInit: registerInit,
            ready:        ready
        };
    })();

    window.fax = app;
})();

fax.ready(function() {
    fax.init();
});